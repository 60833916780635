import { Theme, css } from '@emotion/react'

import { fontSize } from './mixin'

/**
 * Global(전체) style
 * @param theme 글로벌 속성에서 참고할 theme 객체
 */
export default function global(theme: Theme) {
  return css`
    @font-face {
      font-family: TMOBI;
      src:
        local(TMOBI300),
        url(/fonts/TMOBI300.woff) format('woff'),
        url(/fonts/TMOBI300.ttf) format('truetype');
      font-weight: 400; // normal
      font-style: normal;
      font-stretch: normal;
    }

    @font-face {
      font-family: TMOBI;
      src:
        local(TMOBI500),
        url(/fonts/TMOBI500.woff) format('woff'),
        url(/fonts/TMOBI500.ttf) format('truetype');
      font-weight: 500; // bold
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: TMOBI;
      src:
        local(TMOBI500),
        url(/fonts/TMOBI500.woff) format('woff'),
        url(/fonts/TMOBI500.ttf) format('truetype');
      font-weight: 700; // bold
      font-style: normal;
      font-stretch: normal;
    }

    @font-face {
      font-family: TMOBI;
      src:
        local(TMOBI700),
        url(/fonts/TMOBI700.woff) format('woff'),
        url(/fonts/TMOBI700.ttf) format('truetype');
      font-weight: 800; // black
      font-style: normal;
      font-stretch: normal;
    }

    :root {
      // font-weight
      --font-weight-normal: 400;
      --font-weight-medium: 500;
      --font-weight-bold: 600;
      --font-weight-black: 900;
    }

    * {
      font-family: TMOBI, 'Noto Sans', 'Roboto', AppleSDGothicNeo, AppleSDGothicNeo-Regular,
        '맑은 고딕', 'Malgun Gothic', '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim,
        Helvetica, HelveticaNeue, sans-serif;
    }
    html,
    body {
      background: ${theme.background};
      ${fontSize(theme, 16)};
      color: ${theme.text};
      font-weight: var(--font-weight-normal);
    }
    body {
      padding-right: constant(safe-area-inset-right);
      padding-left: constant(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
      padding-left: env(safe-area-inset-left);
    }
    #__next {
      position: relative;
    }
  `
}
