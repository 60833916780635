import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { COLOR } from '@/styles/color'
import { fontSize } from '@/styles/mixin'

interface RefreshPanelProps {
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>
}

const RefreshPanel = ({ onClickButton: onClick }: RefreshPanelProps) => {
  return (
    <Container>
      <RefreshInfoText>
        일시적으로 정보를 <br /> 불러오지 못하였습니다.
      </RefreshInfoText>
      <Button
        type="button"
        onClick={onClick}
      >
        새로고침
      </Button>
    </Container>
  )
}

export default RefreshPanel

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
`

const RefreshInfoText = styled.h3`
  ${({ theme }) => fontSize(theme, 14)};
  font-weight: var(--font-weight-medium);
  color: ${COLOR.gray.color.gray[500]};
  margin-bottom: 16px;
`
const Button = styled.button`
  height: 32px;
  padding: 7px 10px;
  border: 1px solid ${COLOR.gray.color.gray[300]};
  border-radius: 6px;
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--font-weight-medium);
  color: ${COLOR.gray.color.gray[700]};
  text-align: center;
`
