import { useQuery } from '@tanstack/react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { toLower } from 'lodash-es'
import { useCallback, useMemo } from 'react'

import { SectionItem, getSections } from '@/apis/frontman/helpcenter/getSections'
import { CONFIG } from '@/constants/config'
import { QUERY_KEY } from '@/constants/key'

export type CategoryItem = SectionItem & { childSections: SectionItem[] }

const useFaqCategory = () => {
  const { data, status, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY.FAQ_CATEGORY],
    queryFn: () =>
      getSections(CONFIG.ZENDESK_FAQ_CATEGORY_ID, {
        page: 1,
        per_page: 100,
      }),
    select: ({ sections }) => sections,
  })

  const isLoading = useMemo(
    () => !['error', 'success'].includes(status) || isFetching,
    [status, isFetching]
  )

  const categories = useMemo(() => {
    if (data) {
      const currentOS = TmapApp.env.isIOS ? 'i_os' : 'a_nd'
      const depth1 = data.filter(({ parent_section_id, description }) => {
        return !parent_section_id && toLower(description)?.includes(currentOS)
      })
      const depth2 = data.filter(({ parent_section_id, description }) => {
        return parent_section_id && toLower(description)?.includes(currentOS)
      })

      return depth1.map((section) => {
        const childSections = depth2.filter(
          ({ parent_section_id }) => parent_section_id === section.id
        )
        return {
          ...section,
          childSections,
        } as CategoryItem
      })
    }
    return null
  }, [data])

  const getCategoryNames = useCallback(
    (sectionId: number) => {
      const returnNames = []
      const funcIsSectionId = (category: CategoryItem | SectionItem) => category.id === sectionId
      const depth1 = categories?.find((category) => {
        return funcIsSectionId(category) || category.childSections.find(funcIsSectionId)
      })
      const depth2 = depth1?.childSections.find(funcIsSectionId)

      if (depth1) {
        returnNames.push(depth1.name)
      }
      if (depth2) {
        returnNames.push(depth2.name)
      }

      return returnNames
    },
    [categories]
  )

  return {
    isLoading,
    categories,
    getCategoryNames,
    refetch,
  }
}

export const getCategoryBySectionId = ({
  categories,
  sectionId,
}: {
  categories: SectionItem[]
  sectionId?: number
}) => {
  const category = categories.find(({ id }) => id === sectionId)
  return category ? category.name : ''
}

export default useFaqCategory
